import React from "react";
import { Outlet } from "react-router-dom";
import NavigationLearning from "../../components/containers/navigation/navigationLearning";

export default function Assigment() {
  return (
    <>
      <NavigationLearning title="죽을때 까지 기억나는 JavaScript  기초부터 응용까지 | Chapter 1" />
      <div className="mt-16 h-full">
        <Outlet />
      </div>
    </>
  );
}
