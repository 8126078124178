import { createBrowserRouter } from "react-router-dom";
import Notices from "../pages/Dashboard/Notices/Notices";
import Bulletin from "../pages/Dashboard/Bulletin/Bulletin";
import CreateBulletin from "../pages/Dashboard/Bulletin/CreateBulletin";
import DetailBulletin from "../pages/Dashboard/Bulletin/DetailBulletin";
import DashboardLayout from "../layouts/DashboardLayout";
import Home from "../pages/Dashboard/Home/Home";
import Grades from "../pages/Dashboard/Grades/Grades";
import Qna from "../pages/Dashboard/QNA/Qna";
import StatusCode from "../pages/Dashboard/StatusCode/StatusCode";
import CheckHistory from "../pages/Dashboard/CheckHistory/CheckHistory";
import MainLayout from "../layouts/main";
import DraftEmail from "../pages/draftEmail";
import DetailNotices from "../pages/Dashboard/Notices/DetailNotices";
import Course from "../pages/Dashboard/Course/Course";
import routeM from "./routeM";
import routeA from "./routeAdmin";
import CreateQna from "../pages/Dashboard/QNA/CreateQna";
import DetailQna from "../pages/Dashboard/QNA/DetailQna";
import DetailCheckHistory from "../pages/Dashboard/CheckHistory/DetailCheckHistory";
import DashboardLearningLayout from "../layouts/DashboardLearningLayout";
import Learning from "../pages/DashboardLearning/Learning/Learning";
import DetailChapter from "../pages/DashboardLearning/Learning/DetailChapter";
import NoticesLearning from "../pages/DashboardLearning/NoticesLearning/NoticesLearning";
import DetailNoticesLearning from "../pages/DashboardLearning/NoticesLearning/DetailNoticesLearning";
import GradesLearning from "../pages/DashboardLearning/GradesLearning/GradesLearning";
import QnaLearning from "../pages/DashboardLearning/QnaLearning/QnaLearning";
import CreateQnaLearning from "../pages/DashboardLearning/QnaLearning/CreateQnaLearning";
import DetailQnaLearning from "../pages/DashboardLearning/QnaLearning/DetailQnaLearning";
import BulletinLearnig from "../pages/DashboardLearning/BulletinLearning/BulletinLearning";
import CreateBulletinLearning from "../pages/DashboardLearning/BulletinLearning/CreateBulletinLearning";
import DetailBulletinLearning from "../pages/DashboardLearning/BulletinLearning/DetailBulletinLearning";
import Editor from "../pages/Editor";
import DiffEditor from "../pages/DiffEditor";
import ActivityLayout from "../layouts/ActivityLayout";
import VideoChapter from "../pages/LectureStudent/LectureLayout/Activity/VideoChapter";
import LectureLayout from "../pages/LectureStudent/LectureLayout";
import Quiz from "../pages/LectureStudent/LectureLayout/Activity/Quiz";
import Practice from "../pages/LectureStudent/LectureLayout/Activity/Practice";
import Task from "../pages/LectureStudent/LectureLayout/Activity/Task";
import DashboardLayoutProfessor from "../layouts/DashboardLayoutProfessor";
import HomeProfessor from "../pages/DashboardProfessor/HomeProfessor/HomeProfessor";
import SubjectsProfessor from "../pages/DashboardProfessor/SubjectsProfessor/SubjectsProfessor";
import CreateSubjectProfessor from "../pages/DashboardProfessor/SubjectsProfessor/Form/LectureRegisration";
import GradesProfessor from "../pages/DashboardProfessor/GradesProfessor/GradesProfessor";
import NoticesProfessor from "../pages/DashboardProfessor/NoticesProfessor/NoticesProfessor";
import DetailNoticesProfessor from "../pages/DashboardProfessor/NoticesProfessor/DetailNoticesProfessor";
import CreateNoticesProfessor from "../pages/DashboardProfessor/NoticesProfessor/CreateNoticesProfessor";
import IntructurNoticesProfessor from "../pages/DashboardProfessor/NoticesProfessor/IntructurNoticeProfessor";
import DetailIntructurNoticesProfessor from "../pages/DashboardProfessor/NoticesProfessor/DetailIntructurNoticeProfessor";
import BulletinProfessor from "../pages/DashboardProfessor/BulletinProfessor/BulletinProfessor";
import CreateBulletinProfessor from "../pages/DashboardProfessor/BulletinProfessor/CreateBulletinProfessor";
import DetailBulletinProfessor from "../pages/DashboardProfessor/BulletinProfessor/DetailBulletinProfessor";
import QnaProfessor from "../pages/DashboardProfessor/QnaProfessor/QnaProfessor";
import DetailQnaProfessor from "../pages/DashboardProfessor/QnaProfessor/DetailQnaProfessor";
import CheckHistoryProfessor from "../pages/DashboardProfessor/CheckHistoryProfessor/CheckHistoryProfessor";
import DashboardClassroomLayoutProfessor from "../layouts/DashboardClassroomLayoutProfessor";
import Classroom from "../pages/DashboardClassroomProfessor/Classroom/Classroom";
import DetailChapterProfessor from "../pages/DashboardClassroomProfessor/Classroom/DetailChapterProfessor";
import NoticesClassroom from "../pages/DashboardClassroomProfessor/NoticesClassroom/NoticesClassroom";
import DetailNoticesClassroom from "../pages/DashboardClassroomProfessor/NoticesClassroom/DetailNoticesClassroom";
import CreateNoticesClassroom from "../pages/DashboardLearning/QnaLearning/CreateQnaLearning";
import GradesClassroom from "../pages/DashboardClassroomProfessor/GradesClassroom/GradesProfessorClassroom";
import QnaClassroom from "../pages/DashboardClassroomProfessor/QnaClassroom/QnaClassroom";
import DetailQnaClassroom from "../pages/DashboardClassroomProfessor/QnaClassroom/DetailQnaClassroom";
import BulletinClassroom from "../pages/DashboardClassroomProfessor/BulletinClassroom/BulletinClassroom";
import CreateBulletinClassroom from "../pages/DashboardClassroomProfessor/BulletinClassroom/CreateBulletinClassroom";
import DetailBulletinClassroom from "../pages/DashboardClassroomProfessor/BulletinClassroom/DetailBulletinClassroom";
import LectureRegistrationNext from "../pages/DashboardProfessor/SubjectsProfessor/Form/LectureRegisrationNext";
import LectureRegisterVideo from "../pages/DashboardProfessor/SubjectsProfessor/Form/LectureRegisterVideo";
import LectureRegisterAssigment from "../pages/DashboardProfessor/SubjectsProfessor/Form/LectureRegisterAssigment";
import LectureRegisrationPractice from "../pages/DashboardProfessor/SubjectsProfessor/Form/LectureRegisrationPractice";
import Assigment from "../pages/DetailAssignment/Assigment";
import AssigmentLayout from "../pages/DetailAssignment/AssignmentLayout";
import AssigntmentDetail from "../pages/DetailAssignment/AssigntmentDetail";
import AssigntmentPractic from "../pages/DetailAssignment/AssigmentPractic";
const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout></MainLayout>,
    children: [
      ...routeM,
      ...routeA,
      // Student Learning Board
      {
        path: "/dashboard",
        element: <DashboardLayout />,
        children: [
          { index: true, element: <Home /> },
          { path: "home", element: <Home /> },
          { path: "course", element: <Course /> },
          { path: "grades", element: <Grades /> },
          { path: "notices", element: <Notices /> },
          { path: "notices/:id", element: <DetailNotices /> },
          { path: "bulletin", element: <Bulletin /> },
          { path: "bulletin/create", element: <CreateBulletin /> },
          { path: "bulletin/:id", element: <DetailBulletin /> },
          { path: "qna", element: <Qna /> },
          { path: "qna/create", element: <CreateQna /> },
          { path: "qna/:id", element: <DetailQna /> },
          { path: "coding", element: <StatusCode /> },
          { path: "check-history", element: <CheckHistory /> },
          { path: "check-history/:id", element: <DetailCheckHistory /> },
        ],
      },
      {
        path: "/learning/:id",
        element: <DashboardLearningLayout />,
        children: [
          { index: true, element: <Learning /> },
          { path: ":chapterSlug", element: <DetailChapter /> },
          { path: "notices", element: <NoticesLearning /> },
          { path: "notices/:noticeId", element: <DetailNoticesLearning /> },
          { path: "grades", element: <GradesLearning /> },
          { path: "qna", element: <QnaLearning /> },
          { path: "qna/create", element: <CreateQnaLearning /> },
          { path: "qna/:id", element: <DetailQnaLearning /> },
          { path: "bulletin", element: <BulletinLearnig /> },
          { path: "bulletin/create", element: <CreateBulletinLearning /> },
          { path: "bulletin/:id", element: <DetailBulletinLearning /> },
        ],
      },
    ],
  },
  {
    path: "/",
    element: <MainLayout></MainLayout>,
    children: [
      ...routeM,
      ...routeA,
      // Professor Learning Board
      {
        path: "/dashboard-professor",
        element: <DashboardLayoutProfessor />,
        children: [
          { index: true, element: <HomeProfessor /> },
          { path: "home", element: <HomeProfessor /> },
          { path: "subject", element: <SubjectsProfessor /> },
          {
            path: "create-lecture-form",
            element: <CreateSubjectProfessor />,
          },
          {
            path: "create-lecture-form2",
            element: <LectureRegistrationNext />,
          },
          {
            path: "create-lecture-registvideo",
            element: <LectureRegisterVideo />,
          },
          {
            path: "create-lecture-registassignment",
            element: <LectureRegisterAssigment />,
          },
          {
            path: "create-lecture-registpractice",
            element: <LectureRegisrationPractice />,
          },
          { path: "grades", element: <GradesProfessor /> },
          { path: "notices", element: <NoticesProfessor /> },
          { path: "notices/create", element: <CreateNoticesProfessor /> },
          { path: "notices/:id", element: <DetailNoticesProfessor /> },
          {
            path: "notices/instructor",
            element: <IntructurNoticesProfessor />,
          },
          {
            path: "notices/instructor/:id",
            element: <DetailIntructurNoticesProfessor />,
          },
          { path: "bulletin", element: <BulletinProfessor /> },
          { path: "bulletin/create", element: <CreateBulletinProfessor /> },
          { path: "bulletin/:id", element: <DetailBulletinProfessor /> },
          { path: "qna", element: <QnaProfessor /> },
          { path: "qna/:id", element: <DetailQnaProfessor /> },
          { path: "coding", element: <StatusCode /> },
          { path: "check-history", element: <CheckHistoryProfessor /> },
          { path: "check-history/:id", element: <DetailCheckHistory /> },
        ],
      },
      {
        path: "/classroom/:id",
        element: <DashboardClassroomLayoutProfessor />,
        children: [
          { index: true, element: <Classroom /> },
          { path: ":chapterSlug", element: <DetailChapterProfessor /> },
          { path: "notices", element: <NoticesClassroom /> },
          { path: "notices/create", element: <CreateNoticesClassroom /> },
          { path: "notices/:noticeId", element: <DetailNoticesClassroom /> },
          { path: "grades", element: <GradesClassroom /> },
          { path: "qna", element: <QnaClassroom /> },
          { path: "qna/:id", element: <DetailQnaClassroom /> },
          { path: "bulletin", element: <BulletinClassroom /> },
          { path: "bulletin/create", element: <CreateBulletinClassroom /> },
          { path: "bulletin/:id", element: <DetailBulletinClassroom /> },
        ],
      },
    ],
  },
  // activity is lecture, quiz, practice, assigment
  {
    path: "/activity",
    element: <ActivityLayout />,
    children: [
      {
        path: "lecture",
        element: <LectureLayout />,
        children: [{ index: true, element: <VideoChapter /> }],
      },
      {
        path: "quiz",
        element: <LectureLayout />,
        children: [{ index: true, element: <Quiz /> }],
      },
      {
        path: "practice",
        element: <LectureLayout />,
        children: [{ index: true, element: <Practice /> }],
      },
      {
        path: "assigment",
        element: <LectureLayout />,
        children: [{ index: true, element: <Task /> }],
      },
    ],
  },
  {
    path: "/assigment",
    element: <Assigment />,
    children: [
      {
        path: "/assigment",
        element: <AssigmentLayout />,
        children: [
          { index: true, element: <AssigntmentDetail /> },
          { path: "practic", element: <AssigntmentPractic /> },
        ],
      },
    ],
  },
  {
    path: "/draft-email",
    element: <DraftEmail />,
  },
  {
    path: "/editor",
    element: <Editor />,
  },
  {
    path: "/diffeditor",
    element: <DiffEditor />,
  },
]);

export default router;
