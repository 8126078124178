import React from "react";

export default function AssigntmentPractic() {
  return (
    <div className="min-h-screen shadow p-6 mr-6 bg-gray-50">
      {/* Top Bar */}
      <div className="bg-white p-4 rounded-lg shadow mb-6">
        <div className="flex space-x-4 items-center mb-4">
          <select className="px-4 py-2 text-lg border rounded-lg focus:outline-none focus:ring">
            <option>11차시</option>
          </select>
          <select className="px-4 py-2 text-lg border rounded-lg focus:outline-none focus:ring">
            <option>점수 공개</option>
          </select>
          <button className="px-6 py-2 bg-blue-500 text-white rounded-lg">
            설정 저장
          </button>
        </div>

        <div className="flex justify-between items-center">
          <div className="flex space-x-4 items-center text-gray-500">
            <div className="flex space-x-2">
              <span className="text-sm">마감 일자</span>
              <span className="text-sm text-black">2023.12.25</span>
            </div>
            <div className="flex space-x-2">
              <span className="text-sm">제출 일자</span>
              <span className="text-sm text-black">2023.12.25</span>
            </div>
          </div>
          <button className="px-6 py-2 border rounded-lg text-blue-500 border-blue-500">
            제출파일 다운
          </button>
        </div>
      </div>

      <div className="w-full h-full bg-white shadow rounded-lg p-0 overflow-hidden">
        <div className="w-full h-[732px] px-10 py-8 bg-white rounded-2xl flex-col justify-center items-start gap-4 inline-flex">
          <div className="text-[#242424] text-xl font-bold font-['Pretendard'] leading-7">
            실습
          </div>
          <div className="self-stretch h-[624px] rounded-2xl border border-[#e0e0e0] flex-col justify-center items-center gap-6 inline-flex">
            <div className="px-[63px] pt-[42px] pb-[41px] justify-center items-center inline-flex">
              <div className="self-stretch flex-col justify-start items-center gap-4 inline-flex">
                <div className="w-52 h-52 relative">
                  <div className="w-[158px] h-[158px] left-[25px] top-[33px] absolute bg-gradient-to-t from-[#759cff] to-white rounded-full"></div>

                  <div className="w-[158px] h-[158px] left-[25px] top-[33px] absolute bg-gradient-to-t from-[#759cff] to-white rounded-full"></div>
                </div>
                <div className="w-[280px] h-10 px-9 py-2.5 bg-[#2860ef] rounded-[10px] justify-center items-center gap-1 inline-flex">
                  <div className="justify-center items-center gap-1 flex">
                    <button className="text-center text-white text-sm font-bold font-['Pretendard'] leading-tight">
                      IDE 실습하러가기
                    </button>
                    <div className="w-4 h-4 relative"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
