import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import SelectDropdown from "../../components/materials/SelectDropdown";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function AssigmentLayout() {
  const [content, setContent] = useState("");

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ align: [] }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  return (
    <div className="relative px-16 py-8 flex min-h-screen bg-body">
      {/* Main content area */}
      <div className="relative flex-1 pl-6">
        <div className="relative bg-transparent">
          <Outlet />
        </div>
      </div>

      {/* Sidebar */}
      <aside className="relative w-96 bg-white p-6 shadow rounded-lg">
        <div className="space-y-4 mb-4">
          <SelectDropdown
            options={[
              { label: "컴퓨터공학", value: "computer-engineering" },
              { label: "전자공학", value: "electronics-engineering" },
            ]}
            value="computer-engineering"
            placeholder="전공 선택"
            onChange={(e) => console.log(e)}
            size="h-12 rounded-lg px-4"
          />

          <div className="flex flex-col w-full">
            <label className="text-lg mt-4">과목 선택</label>
            <div className="mt-2 bg-blue-50 p-4 rounded-lg flex items-center space-x-2">
              <span role="img" aria-label="Smiling Emoji" className="text-2xl">
                😊
              </span>
              <span className="text-blue-600">제출 완료했어요</span>
            </div>
          </div>

          <div className="flex flex-wrap items-center">
            <label className="text-lg my-2">점수</label>
            <input
              type="text"
              placeholder="점수를 입력해 주세요"
              className="block w-full px-4 py-2 text-lg border rounded-lg focus:outline-none focus:ring"
            />
          </div>

          <div className="flex flex-wrap items-center">
            <label className="text-lg mb-2 mt-2 justify-start">
              과제 코멘트
            </label>
            <div className="mb-6">
              <ReactQuill
                value={content}
                onChange={setContent}
                modules={modules}
                placeholder="내용을 입력해주세요"
              />
            </div>
          </div>
          <button className="w-full bg-blue-500 text-white text-lg py-2 rounded-lg">
            저장
          </button>
        </div>
      </aside>
    </div>
  );
}

export default AssigmentLayout;
