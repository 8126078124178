import React from "react";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
    height: "100%",
    width: "100%",
  },
  section: {
    margin: 10,
    padding: 10,
  },
  pdfViewer: {
    width: "100%",
    height: "100vh",
  },
});

export default function AssigntmentDetail() {
  return (
    <div className="min-h-screen shadow p-6 mr-6 bg-gray-50">
      <div className="bg-white p-4 rounded-lg shadow mb-6">
        <div className="flex space-x-4 items-center mb-4">
          <select className="px-4 py-2 text-lg border rounded-lg focus:outline-none focus:ring">
            <option>11차시</option>
          </select>
          <select className="px-4 py-2 text-lg border rounded-lg focus:outline-none focus:ring">
            <option>점수 공개</option>
          </select>
          <button className="px-6 py-2 bg-blue-500 text-white rounded-lg">
            설정 저장
          </button>
        </div>

        <div className="flex justify-between items-center">
          <div className="flex space-x-4 items-center text-gray-500">
            <div className="flex space-x-2">
              <span className="text-sm">마감 일자</span>
              <span className="text-sm text-black">2023.12.25</span>
            </div>
            <div className="flex space-x-2">
              <span className="text-sm">제출 일자</span>
              <span className="text-sm text-black">2023.12.25</span>
            </div>
          </div>
          <button className="px-6 py-2 border rounded-lg text-blue-500 border-blue-500">
            제출파일 다운
          </button>
        </div>
      </div>

      {/* PDF Viewer */}
      <div className="w-full h-full bg-white shadow-sm rounded-lg p-0 overflow-hidden">
        <PDFViewer style={styles.pdfViewer}>
          <Document>
            <Page size="A4" style={styles.page}>
              <View style={styles.section}>
                <Text>Section #1</Text>
              </View>
              <View style={styles.section}>
                <Text>Section #2</Text>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      </div>
    </div>
  );
}
